import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import { StaticImage } from 'gatsby-plugin-image';
import 'normalize.css';
import React from 'react';
import ContactForm from '../components/form';
import Seo from '../components/seo';
import blob1 from '../images/border_blob1.svg';
import blob2 from '../images/border_blob2.svg';
import blob3b from '../images/border_blob3_b.svg';
import blob3g from '../images/border_blob3_g.svg';
import '../styles/main.scss';

const IndexPage = () => {
  return (
    <>
      <Seo />
      <header>
        <div className='hero'>
          <title>CICOVI : Cabinet d'Ingénieurs Conseils en Viticulture</title>
          <StaticImage
            src='../images/cicovi_logo.svg'
            widht={100}
            height={100}
            alt='Logo CICOVI'
          />
          <div className='hero_title'>
            <h1>Agronomes consultants spécialisés en viticulture</h1>

            <a href='#contact' className='cta'>
              Contactez-nous
            </a>
          </div>
        </div>
        <StaticImage
          src='../images/cicovi_team_original.jpg'
          alt="L'équipe CICOVI"
        />
      </header>
      <main className='fluid'>
        <section id='intro'>
          <p>
            Basés en Languedoc, nous proposons un service de conseil technique
            viticole. Notre cabinet est totalement indépendant. Nos
            consultants, ingénieurs agronomes-œnologues spécialisés en
            viticulture, réalisent un suivi personnalisé de votre vignoble afin
            de répondre aux objectifs technico économiques que vous vous êtes
            fixés.
          </p>
          <span className='bloblist'>
            <img className='blob' src={blob1} alt='' />
            <img className='blob' src={blob1} alt='' />

            <ul>
              <li>Adaptation</li>
              <li>Ecoute</li>
              <li>Confidentialité</li>
              <li>Pragmatisme</li>
              <li>Indépendance</li>
              <li>Rigueur</li>
              <li>Expérience</li>
            </ul>
          </span>

          <p>
            {' '}
            Notre approche est globale. Elle intègre un appui technique, une
            approche économique, tout en tenant compte des exigences
            environnementales et réglementaires, et ce dans une recherche
            constante de performance.
          </p>
          <p>
            {' '}
            Nous pouvons vous accompagner quelle que soit votre approche
            viticole, biologique ou raisonnée, ainsi que dans vos démarches de
            certification spécifiques (<abbr>HVE</abbr>, Terra Vitis, autres
            référentiels qualité).
          </p>
          <p>
            {' '}
            Forts d’une expérience d’une vingtaine d’années, plus d’une centaine
            de domaines de toutes tailles nous font confiance.
          </p>
        </section>
      </main>
      <section id='agrement' className='fluid'>
        <div className='encart'>
          <p>CICOVI est agréé pour le conseil indépendant</p>
          <p>à l’utilisation de produits phytopharmaceutiques</p>
          <p>
            {' '}
            N° Agrément <abbr>LR&nbsp;00988</abbr>
          </p>
        </div>
        <StaticImage src='../images/vendanges.png' className='bg' alt='' />
      </section>
      <main className='fluid'>
        <section id='mission'>
          <h2>Mission principale</h2>
          <p>
            Notre activité d’accompagnement technique repose avant tout sur la
            définition précise des objectifs de production et un suivi terrain
            régulier.
          </p>
          <span className='bloblist'>
            <img className='blob' src={blob2} alt='' />
            <img className='blob' src={blob2} alt='' />
            <ul>
              <li>Observer</li>
              <li>Analyser</li>
              <li>Proposer</li>
            </ul>
          </span>

          <p>
            {' '}
            Nous mettons à profit la période hivernale pour aborder des sujets
            de fond (potentialités du vignoble, orientations de production) et
            raisonner les stratégies de fertilisation, de gestion des sols ainsi
            que les projets de renouvellement du vignoble. Les itinéraires
            techniques sont personnalisés, en adéquation avec votre terroir et
            les vins que vous souhaitez élaborer.{' '}
          </p>
          <p>
            Pendant le cycle végétatif, nous réalisons toutes les deux semaines
            un diagnostic terrain de l’ensemble des thématiques viticoles.
            Chaque visite donne lieu à un compte-rendu écrit. Nos préconisations
            sont adaptées à la situation et personnalisées.
          </p>
          <p>
            Nous pouvons assurer un service complémentaire de suivi des
            maturités en optimisant la date de vendange et en réalisant des
            sélections parcellaires.
          </p>
          <p>
            Nous clôturons l’année avec une réunion de bilan permettant de faire
            le point avec vous sur l’ensemble des gestes réalisés, l’adéquation
            entre les vins obtenus et les objectifs fixés, l’identification des
            forces et faiblesses du millésime et les axes d’améliorations à
            envisager. D’autre part, sur les vignobles irrigués, nous pouvons
            mettre en place un suivi de l’état hydrique des parcelles, basé sur
            des mesures précises du végétal et de leur interprétation via des
            outils d’aide à la décision (<abbr>OAD</abbr>
            ).
          </p>
        </section>
        <section id='annexes'>
          <h2>Missions annexes</h2>

          <div>
            <article>
              <h3>Audits</h3>
              <p>Réalisation d’audits techniques viticoles</p>
              <StaticImage src='../images/loupe.svg' alt='' />
            </article>

            <article>
              <h3>Formation</h3>
              <p>Intervention sur demande sur différents thèmes techniques</p>

              <StaticImage src='../images/formateur.svg' alt='' />
            </article>

            <article>
              <h3>Démarches qualité</h3>
              <p>
                Accompagnement à la mise en place de démarches qualité
                (certification HVE)
              </p>
              <StaticImage src='../images/label.svg' alt='' />
            </article>
          </div>
        </section>
        <section id='equipe'>
          <div className='bg_blob'>
            <img className='blob' src={blob3g} alt='' />
            <img className='blob' src={blob3g} alt='' />
          </div>
          <h2>L'équipe</h2>
          <div className='portraits'>
            <span>
              <StaticImage
                src='../images/JD2.jpg'
                alt='Jean-Dominique Fourment'
                widht={310}
                quality='100'
              />
              <p>Jean-Dominique Fourment</p>
            </span>
            <span>
              <StaticImage
                src='../images/Jerome2.jpg'
                alt='Jérome Fil'
                widht={310}
                quality='100'
              />
              <p>Jérome Fil</p>
            </span>
            <span>
              <StaticImage
                src='../images/LM2.jpg'
                alt='Louis-Marc Sabadie'
                widht={310}
                quality='100'
              />
              <p>Louis-Marc Sabadie</p>
            </span>
            <span>
              <StaticImage
                src='../images/Coline2.jpg'
                alt='Coline Siegel'
                widht={310}
                quality='100'
              />
              <p>Coline Siegel</p>
            </span>
            <span>
              <StaticImage
                src='../images/Julien2.jpg'
                alt='Julien Marzo'
                widht={310}
                quality='100'
              />
              <p>Julien Marzo</p>
            </span>
          </div>
        </section>
        <section id='contact'>
          <span className='bg_blob'>
            <img className='blob' src={blob3b} alt='' />
            <img className='blob' src={blob3b} alt='' />
          </span>

          <div>
            <span>
              <p>
                CICOVI <br />
                17 rue de la Tasque <br />
                11120 Mailhac <br />
                France
              </p>
              <a href='tel:+33468274024' id='callBtn' className='tel'>
                +33 (0)4 68 27 40 24
              </a>

              <a
                className='mail'
                href='mailto&#58;&#37;63o&#110;t%61c%7&#52;&#64;cico%76i&#46;%&#54;3%6F&#109;'
              >
                contact&#64;c&#105;c&#111;vi&#46;com
              </a>
            </span>

            <span>
              <h3>Nous vous recontactons</h3>
              <div id='form'>
                <ContactForm></ContactForm>
              </div>
            </span>
          </div>
        </section>
      </main>
      {/* <footer className='fluid'>
        <p> CICOVI : Cabinet d'Ingénieurs Conseils en Viticulture</p>
      </footer> */}
    </>
  );
};

export default IndexPage;
