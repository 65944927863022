import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
export default React.memo(({ description, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            name
            url
          }
        }
      }
    `
  );

  const { url, name } = site.siteMetadata;
  const baseSchema = [
    {
      '@context': 'http://schema.org',
      // "@type": "WebSite",
      '@type': 'LocalBusiness',
      url,
      name,
      alternateName: name,
      description: description,
      image,
      organization: name,

      canonicalUrl: url,
    },
  ];

  return (
    <Helmet>
      {/* Schema.org tags */}
      <script type='application/ld+json'>{JSON.stringify(baseSchema)}</script>
    </Helmet>
  );
});
