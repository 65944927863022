import { useForm, ValidationError } from '@formspree/react';
import React from 'react';
function ContactForm() {
  const [state, handleSubmit] = useForm('xrgjyzwk');
  if (state.succeeded) {
    return <p>Merci!</p>;
  }
  return (
    <form onSubmit={handleSubmit}>
      <input id='email' type='email' name='email' placeholder='Email' />
      <ValidationError prefix='Email' field='email' errors={state.errors} />
      <textarea id='message' name='message' placeholder='Message' />
      <ValidationError prefix='Message' field='message' errors={state.errors} />
      <button className='cta' type='submit' disabled={state.submitting}>
        Envoyer
      </button>
    </form>
  );
}
export default ContactForm;
