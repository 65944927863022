import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import logo from '../images/cicovi_logo.svg';
import SchemaOrg from './SchemaOrg';
function Seo() {
  const {
    site: {
      siteMetadata: { title, description, url, language },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            language
          }
        }
      }
    `
  );

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: language,
        }}
        title={title}
        titleTemplate={`${title}`}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `image`,
            content: logo,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:url`,
            content: url,
          },
          {
            property: `og:image`,
            content: logo,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            property: `twitter:image`,
            content: logo,
          },
          {
            name: `twitter:description`,
            content: description,
          },
          {
            name: `format-detection`,
            content: 'telephone=no', //prevent Mobile Safari from automatically detecting phone numbers
          },
        ]}
      />
      <SchemaOrg image={logo} description={description} />
    </>
  );
}

export default Seo;
